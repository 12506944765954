import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Entering the classroom, there is an immediate discovery of two sides to the room.`}</p>
    <p>{`There is always more noise towards the back of the classroom. It seems like the two sides of the room are just separated by noise levels.`}</p>
    <p>{`In reality, there are no seperations in sound, no matter what it seems like to our teachers during classes.`}</p>
    <p>{`Without any teachers, the class looks much more different…`}</p>
    <p>{`August 19th 2020, the 10 of us were put into a classroom. It was the first day of school, after 2 months of summer, and after 3 months spent in online learning.`}</p>
    <p>{`It felt like during the first couple weeks of school, no one knew who they were. We were all lost in the thought of how the pandemic affected us. We forgot how to interact, do school work, and “live the school life” with all these rules to follow.`}</p>
    <p>{`It was hard for us to talk about who we were, and share information about ourselves to make new connections. We didn’t know how to.`}</p>
    <p>{`The class is quite diverse. From simple things such as nationalities, to complicated things like our childhoods and the way we were raised. Often throughout the week, there will be conflicts in our class relating to how we treat each other.`}</p>
    <p>{`There are differences in how each person is trying to make the most out of school. Mainly, it is also separated into 2 sides, although this time, there are actual separations.`}</p>
    <p>{`Some of us approach school with a humorous attitude. We are there to entertain our friends, break school rules, and have fun. Although sometimes these “funtivities” will affect the other side of the approach.`}</p>
    <p>{`The other side approaches school as an opportunity to try the best they can and help the school become a better place. This side tries to be productive, although sometimes the humorous side will get in the way of that. That’s when we all start arguing, and getting into trouble.`}</p>
    <p>{`Even though it’s been 7 months of face-face school, we still find ourselves struggling with communicating with each other.  We try to learn each other’s triggers, and anything that could be offensive to different communities that our students are a part of.`}</p>
    <p>{`Luckily, because we are so diverse and different from each other, we are able to learn from each other on how we can be inclusive with everyone, making sure it doesn’t hurt or leave anyone out in our communities.`}</p>
    <p>{`Through social media that we teenagers use frequently, some of us see the impact of slurs, and comments that could offend different groups of people.`}</p>
    <p>{`Unfortunately, the “humorous” side of the room will sometimes not understand how negative words can affect people.`}</p>
    <p>{`Although the other side will help educate the “humorous” side on the history of different terms and why they shouldn’t be used.`}</p>
    <p>{`Meanwhile, the “humorous” side will help the other side relax sometimes from the constant feeling of pressure to be the best in the class. Often, this side forgets the feeling of fun and interacting with their classmates, and the “humorous side” will always provide care to make this side feel better.`}</p>
    <p>{`Funny enough, even though we all have different roots, we all have a connection to Ukraine. This connection helps us discuss different areas of Ukraine, the culture, the music we play that we listened to when we were younger, and how we all hear and sometimes even talk in Ukrainian/Russian when we are mad.`}</p>
    <p>{`“Mad”.`}</p>
    <p>{`Mad is defined many ways in our class. Some start yelling things, some cry, some keep it to themselves. The way we handle our anger, helps us understand each other better. It helps us realize how we can be more inclusive, and not say something or do something that could result in anger in one of our members.`}</p>
    <p>{`For some, anger can lead to making comments they shouldn’t be making, using slurs and other language to help themselves feel better.`}</p>
    <p>{`For situations like these, there are 2 students that are a part of the DEI committee, and will be there to help the student understand each aspect of being diverse, equitable and inclusive with each other.`}</p>
    <p>{`To be honest… we are still learning.`}</p>
    <p>{`Still learning what “diversity” is in our class.`}</p>
    <p>{`Still learning what “equity” is in our class.`}</p>
    <p>{`Still learning what “inclusion” is in our class.`}</p>
    <p>{`We all define it in our own ways, but all our ways come down to one connection.`}</p>
    <p>{`We are diverse with who we are, where we are from, our roots, what we experienced, how we act in certain situations, and why we act that way.`}</p>
    <p>{`We are equitable by making sure that what makes us diverse, doesn’t impact the way we act with each other. In the end of the day, we are all students trying to make their most out of PSI. If some doesn’t understand the “equity” part, others will always be there to help.`}</p>
    <p>{`We are inclusive with learning what equity with our differences looks like. We help each other feel included in all activities, and help each other be proud of who we are.`}</p>
    <p>{`Even though we are still learning every day about who we are and how we can be more diverse, equitable and inclusive with each other, there are so many memories in class, that help us connect more and more through our differences.`}</p>
    <p>{`This is what C306 is like during our free times.`}</p>
    <p>{`We put a different face on during our learning periods...`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      